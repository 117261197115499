@font-face {
    font-family: 'GNLight';
    src: url('../../fonts/gnlight-webfont.eot');
    src: url('../../fonts/gnlight-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnlight-webfont.woff') format('woff'), url('../../fonts/gnlight-webfont.ttf') format('truetype'), url('../../fonts/gnlight-webfont.svg#gnlightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GNMedium';
    src: url('../../fonts/gnmedium-webfont.eot');
    src: url('../../fonts/gnmedium-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnmedium-webfont.woff') format('woff'), url('../../fonts/gnmedium-webfont.ttf') format('truetype'), url('../../fonts/gnmedium-webfont.svg#gnmediumregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GNThin';
    src: url('../../fonts/gnthin-webfont.eot');
    src: url('../../fonts/gnthin-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnthin-webfont.woff') format('woff'), url('../../fonts/gnthin-webfont.ttf') format('truetype'), url('../../fonts/gnthin-webfont.svg#gnthinregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GNBlack';
    src: url('../../fonts/gnblack-webfont.eot');
    src: url('../../fonts/gnblack-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnblack-webfont.woff') format('woff'), url('../../fonts/gnblack-webfont.ttf') format('truetype'), url('../../fonts/gnblack-webfont.svg#gnblackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GNBold';
    src: url('../../fonts/gnbold-webfont.eot');
    src: url('../../fonts/gnbold-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnbold-webfont.woff') format('woff'), url('../../fonts/gnbold-webfont.ttf') format('truetype'), url('../../fonts/gnbold-webfont.svg#gnboldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GNBook';
    src: url('../../fonts/gnbook-webfont.eot');
    src: url('../../fonts/gnbook-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gnbook-webfont.woff') format('woff'), url('../../fonts/gnbook-webfont.ttf') format('truetype'), url('../../fonts/gnbook-webfont.svg#gnbookregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_blackregular';
    src: url('../../fonts/palace-black-webfont.eot');
    src: url('../../fonts/palace-black-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-black-webfont.woff') format('woff'), url('../../fonts/palace-black-webfont.ttf') format('truetype'), url('../../fonts/palace-black-webfont.svg#palace_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_boldregular';
    src: url('../../fonts/palace-bold-webfont.eot');
    src: url('../../fonts/palace-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-bold-webfont.woff') format('woff'), url('../../fonts/palace-bold-webfont.ttf') format('truetype'), url('../../fonts/palace-bold-webfont.svg#palace_boldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_booklight';
    src: url('../../fonts/palace-book-webfont.eot');
    src: url('../../fonts/palace-book-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-book-webfont.woff') format('woff'), url('../../fonts/palace-book-webfont.ttf') format('truetype'), url('../../fonts/palace-book-webfont.svg#palace_booklight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_mediumregular';
    src: url('../../fonts/palace-medium-webfont.eot');
    src: url('../../fonts/palace-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-medium-webfont.woff') format('woff'), url('../../fonts/palace-medium-webfont.ttf') format('truetype'), url('../../fonts/palace-medium-webfont.svg#palace_mediumregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_lightregular';
    src: url('../../fonts/palace-light-webfont.eot');
    src: url('../../fonts/palace-light-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-light-webfont.woff') format('woff'), url('../../fonts/palace-light-webfont.ttf') format('truetype'), url('../../fonts/palace-light-webfont.svg#palace_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_thinlight';
    src: url('../../fonts/palace-thin-webfont.eot');
    src: url('../../fonts/palace-thin-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-thin-webfont.woff') format('woff'), url('../../fonts/palace-thin-webfont.ttf') format('truetype'), url('../../fonts/palace-thin-webfont.svg#palace_thinlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_ultraregular';
    src: url('../../fonts/palace-ultra-webfont.eot');
    src: url('../../fonts/palace-ultra-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-ultra-webfont.woff') format('woff'), url('../../fonts/palace-ultra-webfont.ttf') format('truetype'), url('../../fonts/palace-ultra-webfont.svg#palace_ultraregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'palace_extra_lightregular';
    src: url('../../fonts/palace-xlight-webfont.eot');
    src: url('../../fonts/palace-xlight-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/palace-xlight-webfont.woff') format('woff'), url('../../fonts/palace-xlight-webfont.ttf') format('truetype'), url('../../fonts/palace-xlight-webfont.svg#palace_extra_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}