/* CSS Document */

@import url('https://fonts.googleapis.com/css?family=Nunito|Quicksand|Rubik');

/*
font-family: 'Rubik', sans-serif;
font-family: 'Quicksand', sans-serif;
font-family: 'Nunito', sans-serif;
*/

#headerContainer {
    height: 120px;
    border-bottom: 6px solid #977d4f;
    padding: 0;
    position: relative;
}

#headerContainer .logo {
    width: 145px;
    height: 120px;
}

#headerContainer .logo a {
    display: block;
    background: url(../../img/logo-sub.png) right 10px no-repeat;
    width: 145px;
    height: 120px;
}

#headerContainer .logo a.homepage {
    background: url(../../img/logo.png) 0 0 no-repeat;
}

#headerContainer .columns {
    height: 120px;
}

#header {
    padding: 0;
}

#header>.row {
    margin: 0;
}

#header .columns {
    padding: 0;
    position: static;
}

#topMenu {
    position: static;
    padding-top: 0;
    padding-right: 0;
    height: 70px;
}

#topMenu>ul {
    float: right;
    list-style-type: none;
    padding: 0;
}

#topMenu>ul.visible {
    display: block !important;
    position: absolute;
    left: 0;
    top: 100px;
    z-index: 99999;
    background: url(../../img/dropdown.png) 0 0 repeat;
    width: 100%;
    height: 120px;
    text-align: center;
}

#topMenu>ul.visible li {
    display: inline-block;
    float: none;
    clear: both;
    padding-top: 36px;
}

#topMenu>ul li {
    float: left;
    margin-left: 15px;
}

#topMenu>ul li:first-child {
    margin: 0;
}

#topMenu>ul li a {
    font-size: 18px;
    color: #988456;
    font-family: 'palace_mediumregular';
    font-weight: normal;
}

#topMenu>ul li a:hover {
    text-decoration: none;
    color: #d81921;
}

#topMenu .socialIcons {
    position: absolute;
    top: 34px;
    right: 66px;
}

#topMenu .language {
    position: initial;
    float: right;
    font-size: 15px;
    line-height: 30px;
    margin: 0px 5px;
}

#topMenu .language a {
    color: #94845f;
}

#topMenu .language .nav>li>a {
    padding: 0;
}

#topMenu .language .nav>li>a.dropdown-toggle {
    background: url(../../img/language-caret.png) right center no-repeat;
    padding-right: 11px;
}

#topMenu .language .dropdown-menu {
    left: auto;
    right: 0;
}

#topMenu .language .dropdown-menu>li>a {
    font-size: 12px;
}

#searchContainer {
    background: url(../../img/dropdown.png) 0 0 repeat;
    position: absolute;
    left: 0;
    top: 120px;
    z-index: 99999;
    width: 100%;
    height: 80px;
    padding: 20px 0;
    display: none;
}

#searchContainer input {
    background: none;
    border: 0 none;
    height: 40px;
    line-height: 40px;
    color: #988456;
    font-family: 'palace_mediumregular';
    font-size: 24px;
    opacity: 1 !important;
}

#searchContainer input.searchButton {
    background: url(../../img/searchButton.png) right center no-repeat;
}

.search {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 50px;
}

.search a {
    display: block;
    background: url(../../img/icons.png) 13px 4px no-repeat;
    width: 35px;
    height: 50px;
}

.socialIcons {
    height: 15px;
    border-right: 1px solid #d5d0c3;
    padding-right: 8px;
}

.socialIcons a {
    display: block;
    width: 18px;
    height: 15px;
    float: left;
    background: url(../../img/social-icons.png) 0 0 no-repeat;
    margin-left: 10px;
}

.socialIcons a.facebook {
    background-position: 5px 0;
}

.socialIcons a.twitter {
    background-position: -20px 0;
}

.socialIcons a.instagram {
    background-position: -47px 0;
}

.socialIcons a.youtube {
    background-position: -73px 0;
}

.socialIcons a.vimeo {
    background-position: -99px 0;
}

.socialIcons a.pinterest {
    background-position: -125px 0;
}

.socialIcons a.linkedin {
    background-position: -152px 0;
}

.socialIcons a.gplus {
    background-position: -179px 0;
}

.socialIcons a.tripadvisor {
    background-position: -202px 0;
}

#menuButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: 90px;
    border-left: 0;
}

#menuButton a {
    display: block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
}

#menuButton .openTopMenu {
    background: url(../../img/icons.png) white 29px -63px no-repeat;
}

#menuButton .closeTopMenu {
    background: url(../../img/icons.png) white 33px -165px no-repeat;
    display: none;
}

#sliderContainer {
    padding: 0;
    position: relative;
}

#sliderContainer>.container {
    position: relative;
    z-index: 9999;
}

#sliderContainer .showroom {
    background: url(../../img/promo-shadow-pattern.png) left bottom repeat-x;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: 9998;
}

#slider {
    height: 410px;
    position: relative;
    z-index: 99;
}

#spotContainer {
    padding: 0;
    height: 530px;
}

#spot {
    padding: 0;
    height: 530px;
    text-align: left;
}

#spot .row>div {
    height: 530px;
}

#spot h2 {
    font-size: 48px;
    font-family: 'palace_booklight';
    color: #a58f5e;
    margin-bottom: 25px;
    margin-top: 50px;
}

#spot h2 strong {
    font-family: 'palace_blackregular';
}

#spot p {
    font-family: 'GNBook';
    font-size: 18px;
    color: #a58f5e;
    margin-bottom: 25px;
}

#spot .buttons a {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    color: #ad9d79;
    border: 2px solid #dad1bd;
    font-family: 'GNBlack';
    padding: 0 33px;
    font-size: 12px;
}

#spot .buttons a:hover {
    text-decoration: none;
}

#spot .buttons a:first-child {
    margin-right: 20px;
}

#shoppingStateContainer {
    padding: 0;
    margin-bottom: 20px;
}

#shoppingStateContainer .fun {
    background: url(../../img/eglence.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .shop {
    background: url(../../img/alisveris.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .deluxe {
    background: url(../../img/thumbs/deluxe_double_suite/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .family {
    background: url(../../img/thumbs/family_suite/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .honeymoon {
    background: url(../../img/thumbs/honeymoon_suite/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .king {
    background: url(../../img/thumbs/king_suite/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer a.home {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: #FFF;
    font-family: 'palace_lightregular';
    position: relative;
    /*background: url(../../img/small-shadow-pattern.png) left bottom repeat-x;*/
}

#shoppingStateContainer a.home:hover {
    text-decoration: none;
}

#shoppingStateContainer a.home span {
    position: absolute;
    left: 20px;
    bottom: 60px;
    padding-right: 50px;
    display: block;
    /*background: url(../../img/icons.png) right -301px no-repeat;*/
}

#shoppingStateContainer a.home span strong {
    font-family: 'palace_blackregular';
}

.form-control-promo {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

#reservationModule {
    width: 240px;
    height: 300px;
    background: white;
    position: absolute;
    left: 0;
    top: -350px;
    z-index: 9999999;
    padding: 20px;
}

#reservationModule.subpage {
    top: 0;
    z-index: 8;
    left: 0;
}

#reservationModule .dropdown-menu .glyphicon {
    display: none;
}

#reservationModule .form-group {
    padding: 0;
    margin: 0;
}

#reservationModule .form-group .control-label {
    color: #000000;
    font-size: 9px;
    font-family: 'palace_mediumregular';
    font-weight: normal;
    padding-bottom: 5px;
}

#reservationModule .btn-default {
    width: 85px;
    background: #f5f5f5;
    border: 0 none;
    border-radius: 0;
}

#reservationModule .btn-default .filter-option {
    color: #000;
    font-size: 14px;
    font-family: 'palace_mediumregular';
    font-weight: normal;
}

#reservationModule .btn-default .caret {
    border-top: 9px solid #d91920;
    margin-top: -3px;
}

#reservationModule .form-control {
    border: 0 none;
    background: url(../../img/form-control.png) #f5f5f5 right center no-repeat;
    color: #000;
    font-size: 14px;
    font-family: 'palace_boldregular';
    border-radius: 0;
    height: 30px;
}

#reservationModule .form-control:focus {
    box-shadow: none;
}

#reservationModule .form-control-promo {
    border: 0 none;
    background: #f5f5f5 right center no-repeat;
    color: black;
    font-size: 14px;
    font-family: 'palace_boldregular';
    border-radius: 0;
    height: 30px;
}

#reservationModule .form-control-promo:focus {
    box-shadow: none;
}

#reservationModule .btn-reservation {
    height: 30px;
    background: #d91920;
    border-radius: 3px;
    width: 100%;
    color: #FFF;
    font-size: 11px;
    margin-top: 20px;
    font-family: 'palace_boldregular';
}

#children .btn-default {
    float: right;
}

#pageBaner {
    height: 300px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

#pageBaner>.container {
    height: 300px;
    position: relative;
}

#pageBaner>.container .displayTable {
    display: table;
    height: 300px;
}

#pageBaner>.container .displayTable .displayTableCell {
    display: table-cell;
    vertical-align: bottom;
    height: 300px;
}

#pageBaner>.container .displayTable .displayTableCell h1 {
    color: #FFF;
    font-size: 50px;
    font-family: 'GNBook';
    font-weight: normal;
    padding-left: 0;
}

#tools {
    height: 50px;
    background: #FFF;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 50px;
}

#tools .breadcrumb {
    background: #FFF;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #dbd2bf;
    border-radius: 0;
}

#tools .breadcrumb li {
    height: 49px;
    line-height: 49px;
    color: #664491;
    font-size: 11px;
}

#tools .breadcrumb li a {
    height: 49px;
    line-height: 49px;
    color: #a58f5e;
}

.addthis_sharing_toolbox {
    position: absolute;
    right: 50px;
    top: 12px;
}

#contentContainer {
    background: url(../../img/pattern.png) 0 0 repeat;
    padding-bottom: 59px;
}

#content .description {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 50px;
    border: 1px solid #f3f1ed;
    border-top: 0 none;
    color: #4a4949;
    font-size: 13px;
    font-family: 'palace_booklight';
    background: #FFF;
}

#content .description h2 {
    color: #4a4949;
    font-size: 25px;
    font-family: 'palace_blackregular';
    margin: 30px 0;
}

#content .description .nav {
    border: 0;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 24px;
    color: #ad9d79;
    font-family: 'GNBlack';
    font-size: 12px;
}

#content .description .nav .dropdown {
    width: 100%;
}

#content .description .nav .caret {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #a58f5e;
    display: inline-block;
    float: right;
    height: 0;
    margin-left: 2px;
    position: relative;
    top: 8px;
    vertical-align: middle;
    width: 0;
}

#content .description .nav>li>a {
    border: 2px solid #dbd2bf;
    display: block;
    border-radius: 0;
    color: #ad9d79;
}

#content .description .nav>li>a:hover,
#content .description .nav>li>a:focus {
    background: none;
}

#content .description .nav>li .dropdown-menu {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: 2px solid #dbd2bf;
    border-top: 0;
    margin: 0;
}

#content .description .nav>li .dropdown-menu li a {
    font-family: 'palace_booklight';
}

#content .description .spotText {
    font-size: 16px;
}

#content .right-col {
    padding: 0;
}

#properties ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#properties ul li {
    color: #4a4949;
    font-size: 13px;
    padding: 5px 0;
    border-top: 1px solid #efede9;
}

#properties ul li:first-child {
    border-top: 0;
}

#properties h2 {
    padding-left: 40px;
    background: url(../../img/properties.png) 0 0 no-repeat;
    height: 54px;
    margin-bottom: 10px !important;
    padding-top: 9px;
}

#properties h2.breakfast {
    background-position: 0 0;
}

#properties h2.roomService {
    background-position: 0 -44px;
}

#properties h2.parking {
    background-position: 0 -97px;
}

#properties h2.businessCentre {
    background-position: 11px -153px;
}

#properties h2.patisserie {
    background-position: 0 -210px;
}

#properties h2.internet {
    background-position: 0 -250px;
}

#properties h2.shuttle {
    background-position: 0 -290px;
}

#properties h2.other {
    background-position: 0 -335px;
}

#googleMap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
}

.fromwhere input[type="text"] {
    border: 2px solid #dbd2bf;
    width: 100%;
    padding: 14px 20px;
    font-family: 'GNBlack';
    color: #ad9d79;
    margin-bottom: 10px;
}

.fromwhere a {
    float: right;
    font-family: 'GNBlack';
    color: #fff;
    font-size: 12px;
    padding: 14px 20px;
    background-color: #664491;
    text-decoration: none;
    width: 100%;
    text-align: center;
}

.selectbox .bootstrap-select {
    margin-bottom: 30px !important;
}

.selectbox .bootstrap-select .btn {
    border: 2px solid #dbd2bf;
    border-radius: 0;
    padding: 0;
}

.selectbox .bootstrap-select .btn .filter-option {
    font-size: 12px;
    color: #ad9d79;
    font-family: 'GNBlack';
    display: block;
    height: 36px;
    line-height: 36px;
    padding-left: 18px;
}

.selectbox .bootstrap-select .btn .caret {
    border-top: 7px solid #a58f5e;
}

.selectbox .bootstrap-select .btn:hover,
.selectbox .bootstrap-select .btn:focus,
.selectbox .bootstrap-select .btn:active,
.selectbox .bootstrap-select .btn.active,
.selectbox .bootstrap-select .btn.open .dropdown-toggle.btn-default {
    background: none;
}

.selectbox .bootstrap-select .dropdown-menu li a {
    font-size: 12px;
    color: #333;
}

#galleryListContainer img {
    width: 100%;
    height: 100%;
    display: block;
}

#galleryListContainer .columns {
    margin-bottom: 20px;
}

#galleryListContainer .columns a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

#galleryListContainer .columns a span {
    background: url(../../img/gallery-pattern.png) 0 0 repeat;
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

#galleryListContainer .columns a span .icon {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../img/pattren-zoom.png) center center no-repeat;
}

#galleryListContainer .columns a:hover span {
    display: block;
}

#rightMenu {
    width: 238px;
    padding: 29px 0;
    background: #FFF;
    /*float: right;*/
    border: 1px solid #f3f1ed;
    margin-top: 17px;
}

#rightMenu h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #f3f1ed;
    margin: 0 20px;
    color: #5c3e82;
    font-size: 23px;
    line-height: 30px;
    font-family: 'palace_blackregular';
    margin-bottom: 15px;
}

#rightMenu ul {
    list-style: none;
    list-style-type: none;
    border: 0;
    margin: 0;
    padding: 0;
}

#rightMenu ul li {
    padding: 5px 20px;
}

#rightMenu ul li a {
    color: #9f8757;
    font-size: 16px;
    font-family: 'palace_boldregular';
    display: block;
    margin-bottom: 10px;
}

#rightMenu ul li a:hover {
    color: #5c3e82;
}

#rightMenu ul li.active {
    background: #9f8757;
}

#rightMenu ul li.active>a {
    color: #FFF;
}

#rightMenu ul li ul li {
    padding: 0 20px;
}

#rightMenu ul li ul li.active {
    background: #FFF;
}

#rightMenu ul li ul li.active a {
    color: #4a4949;
}

#rightMenu ul li ul li.active a:hover {
    color: #4a4949;
}

#rightMenu ul li ul li a {
    font-size: 12px;
    padding: 5px 0;
    margin: 0;
    color: #FFF;
}

#rightMenu ul li ul li a:hover {
    color: #FFF;
    text-decoration: underline;
}

.pagination {
    border-radius: 0;
}

.pagination li:first-child a {
    margin-left: 2px;
}

.pagination>li>a,
.pagination>li>span {
    border: 2px solid #dbd2bf;
    border-radius: 0;
    color: #ad9d79;
    font-size: 12px;
    margin-left: 2px;
    font-family: 'palace_blackregular';
    margin-bottom: 2px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-radius: 0;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 0;
}

.seperator {
    background: url(../../img/seperator.png) center top repeat-y;
    height: 225px;
}

.download {
    color: #a58f5e;
    font-size: 12px;
    border: 2px solid #dbd2bf;
    font-family: 'GNBlack';
    padding: 9px 59px 9px 18px;
    background: url(../../img/download-icon.png) right center no-repeat;
    margin-top: 13px;
    display: inline-block;
}

#specialEvents .row {
    padding-top: 40px;
    border-top: 1px solid #efede9;
    margin-top: 40px;
}

#specialEvents .row:first-child {
    padding-top: 0;
    border-top: 0 none;
    margin-top: 0;
}

#specialEvents h3 {
    margin: 0;
    color: #4a4949;
    font-family: 'palace_blackregular';
    font-size: 25px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 3px solid #efede9;
}

#specialEvents .details ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 15px;
}

#specialEvents .details ul li {
    padding: 5px 0;
    border-top: 1px solid #efede9;
}

#specialEvents .details ul li:first-child {
    border-top: 0;
}

#specialEvents .details a {
    color: #ad9d79;
    font-family: 'GNBlack';
    font-size: 10px;
    border: 2px solid #dbd2bf;
    height: 30px;
    line-height: 28px;
    display: inline-block;
    padding: 0 20px;
}

#specialEvents .sliders {
    margin-bottom: 20px;
    overflow: hidden;
}

#footerContainer {
    padding: 0;
}

#footer {
    height: 80px;
    padding: 0;
    /*background: url(../../img/footer.jpg) 0 0 no-repeat;*/
    background-color: #303d77;
    background-size: cover;
}

#footer ul {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

#footer ul li {
    display: inline-block;
}

#footer ul li a {
    font-family: 'palace_boldregular';
    color: #FFF;
    padding: 5px 7px;
    display: block;
}

#footer ul li:first-child a {
    padding-left: 0;
}

#footer ul li:last-child a {
    padding-right: 0;
}

#copyright {
    height: 70px;
    line-height: 70px;
    background: #FFF;
    color: #c0b08e;
    font-size: 12px;
    font-family: 'GNMedium';
}

#copyright a {
    color: #c0b08e;
    display: inline-block;
    padding: 0 5px;
}

#copyright a.grilogo {
    background: url(../../img/gri-logo.png) 0 0 no-repeat;
    width: 64px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 30px;
    padding: 0;
}

#copyright span {
    display: inline-block;
    padding-right: 5px;
}

#copyright .container {
    position: relative;
}

#copyright .socialIcons {
    text-align: center;
}

#copyright .socialIcons a {
    display: inline-block;
    float: none;
}


/*XS*/


/*XS*/

@media (min-width: 320px) and (max-width: 767px) {
    div#rev_slider_1_forcefullwidth {
        margin-bottom: 100px !important;
    }
    #headerContainer {
        height: 70px;
        border: 0 none;
    }
    #headerContainer .logo {
        width: 119px;
        height: 70px;
    }
    #headerContainer .logo a {
        width: 119px;
        height: 70px;
        background: url(../../img/logo-xs-sub.png) center 8px no-repeat;
    }
    #headerContainer .logo a.homepage {
        background: url(../../img/logo-sm.png) 0 0 no-repeat;
    }
    #headerContainer .columns {
        height: 70px;
    }
    #topMenu .socialIcons {
        right: 276px;
    }
    #topMenu .language {
        right: 162px;
        top: 30px;
    }
    #topMenu .search {
        width: 70px;
        height: 70px;
        border-left: 1px solid #f1efeb;
        right: 70px;
        border-right: 1px solid #f1efeb;
    }
    #topMenu .search a {
        width: 70px;
        height: 70px;
        background-position: 25px 26px;
    }
    #topMenu>ul {
        height: auto;
    }
    #topMenu>ul.visible {
        top: 70px;
    }
    #topMenu>ul.visible li {
        padding-top: 15px;
        margin-left: 10px;
    }
    #topMenu>ul.visible li a {
        font-size: 18px;
    }
    #menuButton {
        width: 70px;
        height: 70px;
    }
    #menuButton a {
        width: 70px;
        height: 70px;
    }
    #menuButton .openTopMenu {
        background-position: 20px -72px;
    }
    #menuButton .closeTopMenu {
        background-position: 24px -176px;
    }
    #searchContainer {
        top: 70px;
    }
    #content .description {
        padding: 40px;
    }
    .seperator {
        background: url(../../img/seperator-horizontal.png) center center repeat-x;
        height: 30px;
    }
    #spot {
        height: auto;
        padding: 0 20px 35px;
        text-align: center;
        background: #FFF;
    }
    #spot h2 {
        font-size: 32px;
        margin-bottom: 25px;
        margin-top: 48px;
    }
    #spot h2 strong {
        display: block;
    }
    #spot p {
        font-size: 16px;
    }
    #spot .buttons a {
        width: 196px;
        margin: 0;
    }
    #spot .buttons a:first-child {
        margin: 0 0 18px 0;
    }
    #spot .row>div {
        height: auto;
    }
    #slidecaption {
        border-left: 10px solid #9f8757;
        padding-left: 15px;
    }
    #controls-wrapper {
        margin-left: 0px;
        left: 10px;
    }
    #sliderContainer>.container {
        padding: 0;
    }
    #children .control-label {
        padding-left: 10%;
    }
    #spotContainer {
        background: url(../../img/spot-repeat.jpg) center top no-repeat;
        height: auto;
    }
    #reservationModule {
        position: relative;
        top: 0;
        width: 100%;
    }
    #reservationModule .btn-default {
        width: 90%;
    }
    #shoppingStateContainer .fun {
        height: 264px;
    }
    #shoppingStateContainer .shop {
        height: 264px;
    }
    #pageBaner {
        margin-bottom: 300px;
    }
    #pageBaner>.container .displayTable {
        width: 100%;
        text-align: center;
    }
    #pageBaner>.container .displayTable .displayTableCell {
        vertical-align: middle;
    }
    #pageBaner>.container .displayTable .displayTableCell h1 {
        font-size: 35px;
    }
    #reservationModule.subpage {
        top: 0;
        width: 100%;
        height: 300px;
        position: relative;
    }
    #reservationModule #checkin {
        padding-right: 0;
    }
    #reservationModule #checkout {
        padding-right: 0;
    }
    #reservationModule #reservationButton {
        padding-left: 0;
    }
    #reservationModule #reservationButton .btn-reservation {
        margin-top: 20px;
    }
    #copyright {
        line-height: normal;
        padding: 20px 0;
        height: auto;
    }
    #copyright span {
        display: block;
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }
    #copyright a.grilogo {
        background: url("../../img/gri-logo.png") no-repeat scroll 0 0 transparent;
        height: 12px;
        padding: 0;
        position: relative;
        right: auto;
        top: auto;
        width: 64px;
        margin: 0 auto;
        display: block;
    }
    .otherLinks {
        text-align: center;
        padding: 0 0 10px 0;
    }
}


/*SM*/


/*SM*/

@media (min-width: 768px) {
    #headerContainer {
        height: 90px;
        border: 0 none;
    }
    #headerContainer .logo {
        width: 145px;
        height: 90px;
    }
    #headerContainer .logo a {
        width: 145px;
        height: 90px;
        background: url(../../img/logo-sm-sub.png) right 13px no-repeat;
    }
    #headerContainer .logo a.homepage {
        background: url(../../img/logo-768.png) right center no-repeat;
    }
    #headerContainer .columns {
        height: 90px;
    }
    #topMenu {
        padding-top: 0;
        padding-right: 0;
        height: 90px;
    }
    #topMenu .socialIcons {
        right: 276px;
    }
    #topMenu .language {
        right: 209px;
    }
    #topMenu .search {
        width: 90px;
        height: 90px;
        border-left: 1px solid #f1efeb;
        right: 90px;
        border-right: 1px solid #f1efeb;
    }
    #topMenu .search a {
        width: 90px;
        height: 90px;
        background-position: 34px 34px;
    }
    #topMenu>ul.visible {
        top: 90px;
    }
    #slidecaption {
        font-size: 36px;
        line-height: 36px;
        border-left: 10px solid #9f8757;
        padding-left: 15px;
    }
    #slider {
        height: 760px;
    }
    #controls-wrapper {
        margin-left: 0px;
        left: 10px;
    }
    #spot {
        text-align: center;
        padding: 0 20px 100px;
        height: auto;
    }
    #spot .row>div {
        height: auto;
    }
    #spotContainer {
        background: url(../../img/spot-repeat.jpg) center top no-repeat;
        height: auto;
    }
    #shoppingStateContainer a.home {
        font-size: 28px;
    }
    #shoppingStateContainer a.home span {
        left: 32px;
        /*background: url(../../img/icons.png) right -280px no-repeat;*/
        width: 276px;
        font-size: 42px;
        font-family: 'palace_blackregular';
        text-shadow: 3px 2px 13px black;
    }
    #shoppingStateContainer a.home span strong {
        display: block;
    }
    #searchContainer {
        top: 90px;
    }
    #specialEvents .sliders {
        margin-bottom: 0;
    }
    #children .control-label {
        padding-left: 10%;
    }
    #pageBaner>.container .displayTable {
        height: 180px;
    }
    #pageBaner>.container .displayTable .displayTableCell {
        height: 180px;
    }
    #pageBaner>.container .displayTable .displayTableCell h1 {
        padding-left: 0;
    }
    .seperator {
        background: url(../../img/seperator-horizontal.png) center center repeat-x;
        height: 50px;
    }
    #reservationModule {
        left: 42px;
    }
    #reservationModule .btn-default {
        width: 90%;
        height: 30px;
    }
    #reservationModule.subpage {
        bottom: 0;
        height: 120px;
        top: auto;
        width: 100%;
        z-index: 8;
        position: absolute;
    }
    #reservationModule #checkin {
        padding-right: 10px;
    }
    #reservationModule #checkout {
        padding-right: 10px;
    }
    #reservationModule #reservationButton {
        padding-left: 10px;
    }
    #reservationModule #reservationButton .btn-reservation {
        margin-top: 25px;
    }
    #footer ul {
        padding-top: 25px;
    }
    #footer ul li a {
        padding: 10px 5px;
        font-size: 11px;
    }
    #copyright span {
        display: inline-block;
        padding-right: 12px;
        float: left;
    }
    #copyright .otherLinks {
        float: left;
    }
    #copyright .otherLinks a {
        background: url(../../img/split.png) 0 center no-repeat;
        padding-left: 15px;
    }
    #topMenu>ul.visible li {
        padding-top: 25px;
    }
}


/*MD*/


/*MD*/

@media (min-width: 992px) {
    #headerContainer {
        height: 90px;
        border: 0 none;
    }
    #headerContainer .logo {
        width: 145px;
        height: 90px;
    }
    #headerContainer .logo a {
        width: 145px;
        height: 90px;
        background: url(../../img/logo-md-sub.png) right 8px no-repeat;
    }
    #headerContainer .logo a.homepage {
        background: url(../../img/logo.png) 0 -16px no-repeat;
    }
    #headerContainer .columns {
        height: 90px;
    }
    #topMenu {
        padding-top: 0;
        padding-right: 0;
        height: 90px;
        position: static;
    }
    #topMenu .socialIcons {
        right: 276px;
    }
    #topMenu .language {
        right: 209px;
    }
    #topMenu .search {
        width: 90px;
        height: 90px;
        border-left: 1px solid #f1efeb;
        right: 90px;
        border-right: 1px solid #f1efeb;
    }
    #topMenu .search a {
        width: 90px;
        height: 90px;
        background-position: 34px 34px;
    }
    #slider {
        height: 510px;
    }
    #controls-wrapper {
        margin-left: -486px;
        left: 50%;
    }
    #spotContainer {
        height: 530px;
        padding: 0;
    }
    #spot {
        text-align: left;
        height: 530px;
        padding: 0;
    }
    #spot .row>div {
        height: 530px;
    }
    #content .description {
        border: 0 none;
    }
    #searchContainer {
        top: 90px;
    }
    #pageBaner {
        height: 325px;
    }
    #pageBaner>.container {
        height: 325px;
    }
    #pageBaner>.container .displayTable {
        height: 325px;
    }
    #pageBaner>.container .displayTable .displayTableCell {
        height: 325px;
    }
    #pageBaner>.container .displayTable .displayTableCell h1 {
        font-size: 50px;
        padding-left: 0;
    }
    .seperator {
        background: url(../../img/seperator.png) center top repeat-y;
        height: 225px;
    }
    #reservationModule.subpage {
        top: 12px;
        width: 240px;
        height: 300px;
        position: absolute;
    }
    #reservationModule #checkin {
        padding-right: 0;
    }
    #reservationModule #checkout {
        padding-right: 0;
    }
    #reservationModule #reservationButton {
        padding-left: 0;
    }
    #reservationModule #reservationButton .btn-reservation {
        margin-top: 20px;
    }
    #content .description {
        padding: 50px 15px;
    }
    #specialEvents .sliders {
        margin-bottom: 0;
    }
    #shoppingStateContainer a.home {
        font-size: 28px;
    }
    #shoppingStateContainer a.home span {
        left: 60px;
        /*background: url(../../img/icons.png) right -280px no-repeat;*/
        width: auto;
        font-size: 42px;
        font-family: 'palace_blackregular';
        text-shadow: 3px 2px 13px black;
    }
    #shoppingStateContainer a.home span strong {
        display: inline;
    }
    #footer ul {
        padding-top: 23px;
    }
    #footer ul li a {
        padding: 10px 23px;
        font-size: 14px;
    }
    .fromwhere a {
        width: auto;
    }
}


/*LG*/


/*LG*/

@media (min-width: 1060px) {
    #headerContainer {
        height: 120px;
        border-bottom: 6px solid #977d4f;
    }
    #headerContainer .logo {
        width: 195px;
        height: 120px;
    }
    #headerContainer .logo a {
        width: 145px;
        height: 120px;
    }
    #headerContainer .logo a.homepage {
        background-position: 0 0;
    }
    #headerContainer .columns {
        height: 120px;
    }
    #topMenu {
        padding-top: 75px;
        padding-right: 58px;
        height: 120px;
        position: relative;
    }
    #topMenu .socialIcons {
        top: 34px;
        right: 66px;
    }
    #topMenu .language {
        top: 34px;
        right: 0;
    }
    #topMenu .search {
        bottom: 0;
        right: 0;
        width: 35px;
        height: 50px;
        border: 0 none;
    }
    #topMenu .search a {
        background: url(../../img/icons.png) 13px 4px no-repeat;
        width: 35px;
        height: 50px;
    }
    #topMenu ul.visible {
        background: none;
        float: right;
        height: auto;
        left: auto;
        padding: 0;
        position: relative;
        top: auto;
    }
    #topMenu ul.visible li {
        padding: 0;
    }
    #slider {
        height: 590px;
    }
    #slidecaption {
        font-size: 48px;
        line-height: 55px;
        padding-left: 30px;
        border-left: 10px solid #9f8757;
    }
    #controls-wrapper {
        margin-left: -520px;
        left: 50%;
    }
    #spotContainer {}
    #spot {
        text-align: left;
    }
    #shoppingStateContainer a.home {
        font-size: 28px;
    }
    #shoppingStateContainer a.home span {
        left: 60px;
        /*background: url(../../img/icons.png) right -280px no-repeat;*/
        width: auto;
        font-size: 42px;
        font-family: 'palace_blackregular';
        text-shadow: 3px 2px 13px black;
    }
    #shoppingStateContainer a.home span strong {
        display: inline;
    }
    #searchContainer {
        top: 120px;
    }
    .seperator {
        background: url(../../img/seperator.png) center top repeat-y;
        height: 225px;
    }
    #pageBaner {
        height: 325px;
    }
    #pageBaner>.container {
        height: 325px;
    }
    #pageBaner>.container .displayTable {
        height: 275px;
    }
    #pageBaner>.container .displayTable .displayTableCell {
        height: 275px;
    }
    #pageBaner>.container .displayTable .displayTableCell h1 {
        font-size: 74px;
        padding-left: 30px;
    }
    #reservationModule.subpage {
        top: 12px;
        width: 240px;
        height: 300px;
        position: absolute;
    }
    #reservationModule #checkin {
        padding-right: 0;
    }
    #reservationModule #checkout {
        padding-right: 0;
    }
    #reservationModule #reservationButton {
        padding-left: 0;
    }
    #reservationModule #reservationButton .btn-reservation {
        margin-top: 20px;
    }
    #specialEvents .sliders {
        margin-bottom: 0;
    }
    #content .description {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 50px;
        border: 1px solid #f3f1ed;
        border-top: 0;
    }
    #footer ul {
        padding-top: 23px;
    }
    #footer ul li a {
        padding: 10px 5px;
        font-size: 14px;
    }
}

* {
    outline: none;
}

*:focus {
    outline: 0 none;
}

*:active {
    outline: 0 none;
}

*:hover {
    outline: 0 none;
}

.test-title {
    font-size: 10px;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    z-index: 99999999999;
}

.fancybox-close {
    width: 35px;
    height: 35px;
}

.fancybox-nav span {
    width: 35px;
    height: 35px;
}

.fancybox-overlay {
    z-index: 9999;
}

.fancybox-prev span {
    background-position: 0 -35px;
}

.fancybox-next span {
    background-position: 0 -70px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

#rightMenu h3 {
    font-size: 16px;
}

.margin-top-17 {
    margin-top: 0 !important;
}

.fotorama__stage {
    height: 400px !important;
}

#shoppingStateContainer .burgaz {
    background: url(../../img/ngallery/meetings/burgaz/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .buyukada {
    background: url(../../img/ngallery/meetings/buyukada/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .heybeli {
    background: url(../../img/ngallery/meetings/heybeli/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .sedef {
    background: url(../../img/ngallery/meetings/sedef/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .garden {
    background: url(../../img/meeting/title11.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer .restaurant {
    background: url(../../img/ngallery/facilities/restaurant/1.jpg) right bottom no-repeat;
    background-size: cover;
    height: 390px;
    padding: 0;
}

#shoppingStateContainer p.meeting {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 28px;
    color: #FFF;
    font-family: 'palace_lightregular';
    position: relative;
    background: url(../../img/small-shadow-pattern.png) left bottom repeat-x;
}

.fotorama__stage {
    height: 320px !important;
}

.xscroll {
    /*max-width: 698px;*/
    overflow-y: hidden;
    /*height: 120px;*/
}

.xscroll img {
    margin: 0 10px 0 0;
    /*width: 150px;
    height: 100px;*/
}

.xscroll img {
    display: block;
}

.xscroll a {
    display: inline-table;
    position: relative;
    /*width: 150px;*/
}

.xscroll a span {
    /*background: url(../../img/gallery-pattern.png) 0 0 repeat;*/
    display: none;
    /*width: 150px;
    height: 100px;*/
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.xscroll a span .icon {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../img/pattren-zoom.png) center center no-repeat;
}

.xscroll a:hover span {
    display: block;
}

#back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 9999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    color: #444;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-90deg);
}

#back-to-top.show {
    opacity: 1;
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-90deg);
}

.sm-typo {
    font-size: 15px;
    color: #988456;
    font-family: 'palace_mediumregular';
    font-weight: normal;
    margin: 20px 0 10px 0;
}

.fancybox-title>a {
    color: #9f8757;
    font-size: 16px;
    font-family: 'palace_boldregular';
    display: block;
    text-align: right;
}

#modallink>a {
    color: #9f8757;
    font-size: 16px;
    font-family: 'palace_boldregular';
    display: block;
    text-align: center;
}

#modallink>a:hover {
    color: #5c3e82;
}

.fancybox-title>a:hover,
#modallink>a {
    color: #5c3e82;
}

#modallink>.fancybox-nav {
    display: none;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #9f8757 !important;
}

a.carousel-control {
    position: absolute;
    margin-top: 117px;
}

a.left {
    height: 100px;
    z-index: 9999;
    left: 41px !important;
}

a.right {
    height: 100px;
    z-index: 9999;
    right: 41px !important;
}


/*

.carousel-control .glyphicon-chevron-left {
    margin-left: -55px;
}

.carousel-control .glyphicon-chevron-right {
    right:5px;
}

.row.arrowtest .fotorama__arr--prev {
    left: 0 !important;
    background:url(../../img/fotorama-prev.png) #664491 center center no-repeat;
    bottom:-60px;
    position: inital;
    width: 32px;
    height:100px;
    top: 50%;
    margin-top: 0;
    float: left;
}
.row.arrowtest .fotorama__arr--next {
    right: 0 !important;
    background:url(../../img/fotorama-next.png) #664491 center center no-repeat;
    bottom:-60px;
    position: inital;
    width: 32px;
    height:100px;
    top: 50%;
    margin-top: 0;
    float: right;
}

*/

.mCSB_container {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.pos-ab {
    position: absolute;
    height: 100px;
}

.next-arrow {
    float: right;
    text-align: right;
    position: relative;
    margin-top: -120px;
}

#reservationModule.subpage {
    height: 320px !important;
}

.owl-prev {
    position: absolute;
    top: 30px;
    left: 10px;
    background: url('../../img/back.png');
    height: 43px;
    width: 43px;
    opacity: 0.6;
}

.owl-next {
    position: absolute;
    top: 30px;
    right: 10px;
    background: url('../../img/forward.png');
    height: 43px;
    width: 43px;
    opacity: 0.6;
}

.not .owl-nav {
    display: none !important;
}

.owl-item {
    width: 150px !important;
}

.fancybox-opened {
    z-index: 99999999999999;
}

.datepicker td span.active,
.datepicker td.active.day,
.datepicker td.active.year {
    background: #664491 !important;
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
}

table th {
    font-weight: bold;
}

table td,
table th {
    padding: 9px 10px;
    text-align: left;
}


/* Mobile */

@media only screen and (max-width: 767px) {
    table.responsive {
        margin-bottom: 0;
    }
    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 40%;
        overflow: hidden;
        overflow-x: scroll;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
    .pinned table {
        border-right: none;
        border-left: none;
        width: 100%;
    }
    .pinned table th,
    .pinned table td {
        white-space: nowrap;
    }
    .pinned td:last-child {
        border-bottom: 0;
    }
    div.table-wrapper {
        position: relative;
        margin-bottom: 0;
        overflow: hidden;
        border-right: 1px solid #ccc;
    }
    div.table-wrapper div.scrollable {
        margin-left: 40%;
    }
    div.table-wrapper div.scrollable {
        overflow: scroll;
        overflow-y: hidden;
    }
    table.responsive td,
    table.responsive th {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }
    table.responsive th:first-child,
    table.responsive td:first-child,
    table.responsive td:first-child,
    table.responsive.pinned td {
        display: none;
    }
}

.fs-68 {
    font-size: 60px !important;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    border-top: none;
}

.language.trip {
    right: 240px !important;
    border-right: 1px solid #d5d0c3;
    padding-right: 15px;
}

.header_trip {
    background: #9f8757;
    border-radius: 3px;
    color: #FFF !important;
    padding: 5px;
    font-size: 12px !important;
}

.right_trip {
    width: 238px;
    float: right;
    margin-top: 20px;
}

a.right_trip:hover {
    cursor: pointer;
    text-decoration: none;
}

@media (min-width: 1200px) {
    #spotContainer {
        height: 450px;
    }
    #spot .row>div {
        height: 450px;
    }
    #reservationModule.subpage {
        height: 320px;
    }
}

@media (min-width: 1060px) {
    #spotContainer {
        height: 470px;
    }
    #spot .row>div {
        height: 470px;
    }
    #reservationModule.subpage {
        height: 300px;
    }
}

@media (max-width: 1060px) {
    .language.trip {
        right: 450px !important;
    }
}

@media (min-width: 992px) {
    #spot {
        height: 550px;
    }
    #reservationModule.subpage {
        height: 300px !important;
    }
}

@media (max-width: 600px) {
    .fotorama__nav-wrap {
        margin-top: -70px;
        z-index: 9999;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .table-responsive>.fixed-column {
        display: none !important;
    }
    #reservationModule.subpage {
        max-height: 200px !important;
    }
    #pageBaner>.container .displayTable,
    #pageBaner>.container .displayTable .displayTableCell {
        height: 50px;
    }
}

@media (max-width:767px) {
    .mobile-none {
        display: none;
    }
    #reservationModule {
        max-height: 110px !important;
    }
    #pageBaner {
        margin-bottom: 110px;
    }
    #pageBaner>.container {
        height: 400px !important;
    }
    #pageBaner {
        height: 400px;
    }
    #pageBaner>.container .displayTable {
        height: 400px;
    }
}

#slidecaption {
    color: white;
}

#specialEvents {
    margin-bottom: 20px;
}

#shoppingStateContainer a.room-list {
    position: absolute;
    bottom: 20px;
    background: none;
    width: auto;
    height: auto;
    left: 40px;
    text-shadow: 3px 2px 2px black;
    font-size: 19px;
}

.header-top-right {
    position: absolute;
    top: 34px;
    right: 70px;
    color: #94845f;
    text-decoration: none;
    font-size: 14px;
    font-family: 'palace_blackregular';
}

#header-social-icons {
    display: inline-block;
}

#header-social-icons a {
    display: inline-block;
    padding: 0px 5px;
    color: #9f8757;
    font-size: 16px;
}

.map-marker {
    font-size: 20px;
    margin: 0px 10px;
    cursor: pointer;
    display: inline-block;
    color: #9f8757;
}

.owl-nav.disabled {
    display: block !important;
    color: transparent;
}

.fancybox-overlay {
    background: rgba(47, 58, 67, 0.8) !important;
}

@media (min-width:767px) {
    #copyright .socialIcons {
        text-align: right;
        display: inline;
        border-right: 0px;
        float: right;
    }
}


/*FONT STYLES*/


/*
font-family: 'Rubik', sans-serif;
font-family: 'Quicksand', sans-serif;
font-family: 'Nunito', sans-serif;
*/

#pageBaner>.container .displayTable .displayTableCell h1 {
    font-family: 'Quicksand', sans-serif;
}

#shoppingStateContainer a.room-list {
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
}

.welcome-block {
    font-size: 12px !important;
}

#spot p {
    font-size: 12px !important;
}

b,
strong {
    font-weight: bolder;
    font-size: 16px;
    font-family: sans-serif;
}